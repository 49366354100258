<!-- This container is designed to be reusable in the teams and players views, -->
<!-- in such views is required to pick one item from a central grid. -->
<!-- This container will work correctly only for items with 115px width and 9px margin, -->
<!-- due to it's internal items container max-width. -->
<template>
  <div class="is-bordered-box-shadow-main main-container">
    <div v-if="$route.name === 'players'" class="main-container__players">
      <div class="main-container__players__team">
        <div class="main-container__players__team__logo">
          <img :src="`https://az755631.vo.msecnd.net/teams-80/${$route.params.teamId}.png`" alt="" />
        </div>
        <div class="main-container__players__team__name">
          {{ $route.query.teamName || '' }}
        </div>
      </div>
    </div>
    <div v-else-if="leagueLogo" class="league-logo">
      <img v-show="isTournamentLogoAllowed" :src="leagueLogo" alt="Liga" />
    </div>
    <div class="items-container">
      <slot />
    </div>
    <div v-if="hint" class="hint">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CentralItemsContainer',
  components: {},
  props: {
    leagueLogo: {
      type: String,
      default: '/images/logo-liga-mx-footer.png',
    },
    hint: {
      type: String,
      default: 'Haz click para seleccionar un equipo',
    },
  },
  computed: {
    ...mapState('leagueGeneral', ['objectTournamentSelected']),
    isTournamentLogoAllowed() {
      return this.objectTournamentSelected.id === 1;
    },
  },
};
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 79px;
  border-radius: 20px;
  position: relative;

  &__back-button {
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &__players {
    margin: 36px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__team {
      display: flex;
      justify-content: center;
      align-items: center;

      &__logo {
        height: 48px;
        width: 48px;

        & > img {
          height: 48px;
          width: 48px;
        }
      }

      &__name {
        padding-top: 8px;
        font-family: Avenir-Bold, sans-serif;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.43;
        letter-spacing: -0.4px;
        color: #132739;
        margin-left: 14px;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 770px) {
    &__back-button {
      display: block;
    }

    &__players {
      margin: 18px 0 8px;
      &__back-button {
        display: none;
      }
    }
  }

  @media screen and (max-width: 460px) {
    &__players {
      width: 100%;
      margin: 12px 0 8px;
      justify-content: center;

      &__team {
        &__logo {
          height: 32px;
          width: 32px;

          & > img {
            height: 32px;
            width: 32px;
          }
        }
        &__name {
          font-size: 29px;
          margin-left: 8px;
        }
      }
    }
  }

  @media screen and (max-width: 350px) {
    &__players {
      width: 100%;
    }
  }
}

.league-logo {
  width: 215px;
  margin: 35px 0;
}

.items-container {
  max-width: 798px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hint {
  margin-top: 4px;
  font-size: 11px;
  font-weight: 300;
  color: #72707c;
}
</style>
